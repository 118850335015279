import React from 'react'
import cx from 'classnames'
import { RichText } from '@ordaos/nucleus'

import DangerousHTML from '../DangerousHTML'

import './Section.scss'

const Section = ({
	twoColumn,
	inverted,
	invertedDark,
	title,
	subtitle,
	className,
	children,
	width,
	img,
	imgFirst,
	id,
}) => {
	const classNames = cx('section', {
		[`${className}-section`]: className,
		[`section--${width}`]: !!width,
		[`section--inverted`]: inverted,
		[`section--inverted-dark`]: invertedDark,
		[`section--two-column`]: twoColumn,
		['section--two-up']: img,
	})

	return (
		<section id={id} className={classNames}>
			{img && imgFirst && (
				<img
					src={img}
					alt={title ?? subtitle}
					className='section--content-img'
				/>
			)}
			<div className='section--content-wrapper'>
				<RichText inverted={invertedDark}>
					{title && (
						<DangerousHTML tag='h2' className='h2 section--title'>
							{title}
						</DangerousHTML>
					)}
					{subtitle && (
						<DangerousHTML className='section--subtitle'>
							{subtitle}
						</DangerousHTML>
					)}
					<div className='section--content'>{children}</div>
				</RichText>
			</div>
			{img && !imgFirst && (
				<img src={img} alt={title} className='section--content-img' />
			)}
		</section>
	)
}

export default Section
