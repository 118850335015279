import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import CMS from '../../components/CMS'
import Section from '../../components/Section'
import Layout from '../../layout/BasicPage'

const AboutUs = ({ data, location }) => {
	return (
		<Layout width='wide'>
			<SEO {...SEO.metadata(data.about)} href={location.href} />
			<Layout.Header
				title={CMS.title(data.about)}
				subtitle={CMS.subtitle(data.about)}
				hero='gyro'
			/>

			<Layout.Inset>
				<Section title='Our Belief' twoColumn>
					<CMS.Body {...data.about} />
				</Section>
			</Layout.Inset>

			<Layout.Links {...data.about} />
		</Layout>
	)
}

export default AboutUs

export const query = graphql`
	query About {
		about: file(
			sourceInstanceName: { eq: "pages" }
			relativePath: { eq: "about.md" }
		) {
			...SEOFragment
			...CMSFragment
		}
	}
`
